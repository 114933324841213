@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

.hero {
	color: white;
}

.hero-section {
	display: flex;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	background: #12062b;
	/* background-image: url(./../../Assets/hero-bg.jpeg); */
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background: radial-gradient(
		circle,
		rgba(0, 77, 121, 1) 4%,
		rgba(1, 1, 42, 1) 100%,
		rgba(19, 9, 47, 1) 100%
	);
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 5%;
}
.hero-section h1 {
	color: white;
	font-size: 65px;
	font-weight: 700;
	width: 70%;
}
.hero-section p {
	color: white;
	font-size: 25px;
	width: 50%;
	line-height: 40px;
}
.hero-section button {
	padding: 8px 10px;

	font-size: 22px;
	cursor: pointer;
	font-family: VT323, monospace;
}

.button-container {
	height: 70px;
}
.hero-section .mint-button {
	width: auto;
	max-width: 180px;
	padding: 10px;
	background-color: aliceblue;
	color: #000;
	font-size: 22px;
	font-family: VT323, monospace;
	border: 1px solid black;
	box-shadow: -2px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	cursor: pointer;
}
.hero-section .mint-button a {
	text-decoration: none;
	color: #000;
}
.hero-section.mint-button:hover {
	margin-top: 55px;
	box-shadow: -2px 4px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
}

@media only screen and (max-width: 420px) {
	.hero-section {
		justify-content: flex-start;
		align-content: center;
		background-position: center;
	}
	.hero-section h1 {
		color: white;
		font-size: 55px;
		font-weight: 700;
		width: 100%;
		text-align: center;
		margin-top: 20%;
	}
	.hero-section p {
		width: 100%;
	}
}
