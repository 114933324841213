@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap");
.mintpagewrapper {
	height: 100%;
	width: 100%;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.Heading {
	font-family: "Fira Sans", sans-serif;
	color: white;
	font-weight: 500;
	font-size: 55px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.nftimage {
	display: flex;
	justify-content: center;
}
.nftimage img {
	width: auto;
	max-width: 350px;
	margin-bottom: 30px;
}
.textafterimage {
	color: #dbd1a8;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 20px;
}
.minted-nfts {
	color: #dbd1a8;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 20px;
}

.quantity-selector {
	display: flex;
	flex-direction: row;
	width: 300px;
	justify-content: space-around;
	color: white;
	margin-bottom: 20px;
	cursor: pointer;
	font-family: "Fira Sans", sans-serif;
}

.quantity-button {
	background-color: transparent;
	padding: 0;
	font-size: 56px;
	color: white;
	border: none;
	line-height: 50px;
	font-weight: 600;
	width: 10%;
	cursor: pointer;
	font-family: "Fira Sans", sans-serif;
}

.mintbutton {
	max-width: 200px;
	width: 50%;
	padding: 12px 20px;
	color: black;
	font-weight: 700;
	font-size: 20;
	background-color: white;
	border-radius: 7px;
	margin: 20px auto;
	cursor: pointer;
	border: none;
	font-family: "Fira Sans", sans-serif;
}

body { 
	min-height: 100%;
	background: radial-gradient(circle, #1e2a3e 20%, #1a263a 100%, #424b5f 100%) no-repeat center center fixed;
	/* background-image: url("./../../assets/pexels-flower-power-4058667-scaled.jpg"); */
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-position: center; 
}

html { height: 100% }

@media (max-width: 450px) {
	.mintpagewrapper {
		padding-top: 30%;
	}
	.Heading {
		font-size: 45px;
		text-align: center;
	}
	.nftimage img {
		width: 80%;
	}
	.textafterimage {
		margin: 20px;
	}
	.footer p {
		margin: 0;
		width: 80%;
	}
}
