.navbarwrapper {
	width: 100%;
	max-width: 100vw;
	display: flex;
	position: relative;
	top: 0%;
	flex-direction: row;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.logowrapper {
	width: 50%;
	display: flex;
	justify-content: flex-start;
}
.logowrapper p {
	font-size: 30px;
	color: white;
	margin-left: 30px;
}
.logo {
	margin-left: 30px;
	max-width: 300px;
}

.logo img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.nav-menu {
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-left: auto;
}

.nav-link {
	color: white;
	font-size: 20px;
	margin: 5px 15px;
	text-decoration: none;
}

.social-icon-wrapper {
	margin-right: 0px;
	width: 40px;
	height: 40px;
	padding: 0px;
}
.social-icon {
	width: 100%;
	height: auto;
}
