@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbarwrapper {
	width: 100%;
	max-width: 100vw;
	display: flex;
	position: relative;
	top: 0%;
	flex-direction: row;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.logowrapper {
	width: 50%;
	display: flex;
	justify-content: flex-start;
}
.logowrapper p {
	font-size: 30px;
	color: white;
	margin-left: 30px;
}
.logo {
	margin-left: 30px;
	max-width: 300px;
}

.logo img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.nav-menu {
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-left: auto;
}

.nav-link {
	color: white;
	font-size: 20px;
	margin: 5px 15px;
	text-decoration: none;
}

.social-icon-wrapper {
	margin-right: 0px;
	width: 40px;
	height: 40px;
	padding: 0px;
}
.social-icon {
	width: 100%;
	height: auto;
}

.mintpagewrapper {
	height: 100%;
	width: 100%;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.Heading {
	font-family: "Fira Sans", sans-serif;
	color: white;
	font-weight: 500;
	font-size: 55px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.nftimage {
	display: flex;
	justify-content: center;
}
.nftimage img {
	width: auto;
	max-width: 350px;
	margin-bottom: 30px;
}
.textafterimage {
	color: #dbd1a8;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 20px;
}
.minted-nfts {
	color: #dbd1a8;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 20px;
}

.quantity-selector {
	display: flex;
	flex-direction: row;
	width: 300px;
	justify-content: space-around;
	color: white;
	margin-bottom: 20px;
	cursor: pointer;
	font-family: "Fira Sans", sans-serif;
}

.quantity-button {
	background-color: transparent;
	padding: 0;
	font-size: 56px;
	color: white;
	border: none;
	line-height: 50px;
	font-weight: 600;
	width: 10%;
	cursor: pointer;
	font-family: "Fira Sans", sans-serif;
}

.mintbutton {
	max-width: 200px;
	width: 50%;
	padding: 12px 20px;
	color: black;
	font-weight: 700;
	font-size: 20;
	background-color: white;
	border-radius: 7px;
	margin: 20px auto;
	cursor: pointer;
	border: none;
	font-family: "Fira Sans", sans-serif;
}

body { 
	min-height: 100%;
	background: radial-gradient(circle, #1e2a3e 20%, #1a263a 100%, #424b5f 100%) no-repeat center center fixed;
	/* background-image: url("./../../assets/pexels-flower-power-4058667-scaled.jpg"); */
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-position: center; 
}

html { height: 100% }

@media (max-width: 450px) {
	.mintpagewrapper {
		padding-top: 30%;
	}
	.Heading {
		font-size: 45px;
		text-align: center;
	}
	.nftimage img {
		width: 80%;
	}
	.textafterimage {
		margin: 20px;
	}
	.footer p {
		margin: 0;
		width: 80%;
	}
}

.hero {
	color: white;
}

.hero-section {
	display: flex;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	background: #12062b;
	/* background-image: url(./../../Assets/hero-bg.jpeg); */
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background: radial-gradient(
		circle,
		rgba(0, 77, 121, 1) 4%,
		rgba(1, 1, 42, 1) 100%,
		rgba(19, 9, 47, 1) 100%
	);
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 5%;
}
.hero-section h1 {
	color: white;
	font-size: 65px;
	font-weight: 700;
	width: 70%;
}
.hero-section p {
	color: white;
	font-size: 25px;
	width: 50%;
	line-height: 40px;
}
.hero-section button {
	padding: 8px 10px;

	font-size: 22px;
	cursor: pointer;
	font-family: VT323, monospace;
}

.button-container {
	height: 70px;
}
.hero-section .mint-button {
	width: auto;
	max-width: 180px;
	padding: 10px;
	background-color: aliceblue;
	color: #000;
	font-size: 22px;
	font-family: VT323, monospace;
	border: 1px solid black;
	box-shadow: -2px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	cursor: pointer;
}
.hero-section .mint-button a {
	text-decoration: none;
	color: #000;
}
.hero-section.mint-button:hover {
	margin-top: 55px;
	box-shadow: -2px 4px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
}

@media only screen and (max-width: 420px) {
	.hero-section {
		justify-content: flex-start;
		align-content: center;
		background-position: center;
	}
	.hero-section h1 {
		color: white;
		font-size: 55px;
		font-weight: 700;
		width: 100%;
		text-align: center;
		margin-top: 20%;
	}
	.hero-section p {
		width: 100%;
	}
}

.hero {
	color: white;
}

.hero-section {
	display: flex;
	height: 100%;
	width: auto;
	max-width: 100vw;
	background: #12062b;
	/* background-image: url(./../../Assets/hero-bg.jpeg); */
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background: radial-gradient(
		circle,
		rgba(0, 77, 121, 1) 4%,
		rgba(1, 1, 42, 1) 100%,
		rgba(19, 9, 47, 1) 100%
	);
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding: 5%;
}
.hero-section h1 {
	color: white;
	font-size: 65px;
	font-weight: 700;
	width: 70%;
}
.hero-section p {
	color: white;
	font-size: 25px;
	width: 50%;
	line-height: 40px;
}
.hero-section button {
	padding: 8px 10px;

	font-size: 22px;
	cursor: pointer;
	font-family: VT323, monospace;
}

.button-container {
	height: 70px;
}
.hero-section .mint-button {
	width: auto;
	max-width: 180px;
	padding: 10px;
	background-color: aliceblue;
	color: #000;
	font-size: 22px;
	font-family: VT323, monospace;
	border: 1px solid black;
	box-shadow: -2px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -5px 7px 0px 0px rgba(44, 44, 44, 0.75);
	cursor: pointer;
}
.hero-section.mint-button:hover {
	margin-top: 55px;
	box-shadow: -2px 4px 0px 0px rgba(44, 44, 44, 0.75);
	-webkit-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
	-moz-box-shadow: -3px 5px 0px 0px rgba(44, 44, 44, 0.75);
}

@media only screen and (max-width: 420px) {
	.hero-section {
		justify-content: flex-start;
		align-content: center;
		background-position: center;
	}
	.hero-section h1 {
		color: white;
		font-size: 55px;
		font-weight: 700;
		width: 100%;
		text-align: center;
		margin-top: 20%;
	}
	.hero-section p {
		width: 100%;
	}
}

